<template>
  <div class="test1">
    {{selectedTab}}
    <v-tabs v-model="selectedTab">
      <v-tab
        v-for="(t, index) in 10"
        :key="index"
      >
        {{t}}
      </v-tab>
    </v-tabs>
    <keep-alive>
      <component
        :is="componentName"
        :key="keepAliveKey"
        v-keep-scroll-position
      />
    </keep-alive>
  </div>
</template>

<script>
export default {
  components: {
    test1_1: () => import('./test1_1'),
    test1_2: () => import('./test1_2')
  },
  data () {
    return {
      selectedTab: 0
    }
  },
  mounted () {

  },
  computed: {
    componentName () {
      return `test1_${this.selectedTab + 1}`
    },
    keepAliveKey () {
      return `${this.componentName}`
      // return `${this.componentName}-${this.uuid()}`
    }
  },
  methods: {

  }

}
</script>

<style lang="stylus" scoped>
.test1
  display flex
  flex-grow 1
  overflow hidden
  flex-direction column
</style>
